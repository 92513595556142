<template>
    <FullscreenOverlay
        v-model="appointment_details_dialog"
        :width="is_mobile ? 960 : 480"
        class="appointment-details-view-dialog asdsad"
        @cancel="onCloseDetailsView"
    >
        <!-- 1. HEADER -->
        <div class="appointment-details-view-dialog__nav">
            <!-- 1.1. HEADER NAVBAR -->
            <TopNavigation no-wrapper>
                <template v-slot:prepend>
                    <RisifyButton
                        text
                        icon
                        large
                        @click="
                            appointment_details_dialog = false;
                            appointment_options_menu = false;
                        "
                    >
                        <CustomIcon
                            color="primary_dark"
                            name="arrow-right"
                        />
                    </RisifyButton>
                </template>
                <template v-slot:main>
                    {{
                        capitalizeString(
                            moment(selected_appointment && selected_appointment.start_time)
                                .tz(auth_store.getUserTimezone())
                                .format("dddd, D MMM YYYY, HH:mm")
                        )
                    }}
                </template>
                <template v-slot:append>
                    <RisifyButton
                        text
                        icon
                        large
                        color="dark-green"
                        @click="appointment_options_menu = true"
                    >
                        <CustomIcon name="menu-dots-horizontal" />
                    </RisifyButton>
                </template>
            </TopNavigation>

            <!-- 1.2. DROPDOWN MENU -->
            <div
                v-if="appointment_options_menu && auth_store.user && selected_appointment"
                v-click-outside="
                    () => {
                        if (appointment_options_menu) appointment_options_menu = false;
                    }
                "
                :class="{
                    'appointment-details-view-options-menu': true
                }"
            >
                <div
                    v-if="auth_store.user.role === UserRole.THERAPIST"
                    class="appointment-details-view-options-menu-item py-3 px-4"
                    @click="
                        () => {
                            appointment_create_dialog = true;
                            appointment_options_menu = false;
                        }
                    "
                >
                    <div
                        class="appointment-details-view-options-menu-item__header mb-3 text-link-2"
                    >
                        Umów wizytę
                    </div>
                    <div class="appointment-details-view-options-menu-item__content text-link-3">
                        <CustomIcon
                            size="14"
                            name="info-circle"
                            class="mr-2"
                        />
                        <span>Ze swoim pacjentem</span>
                    </div>
                </div>
                <div
                    v-if="
                        selected_appointment.status === AppointmentStatus.UNPAID ||
                        selected_appointment.status === AppointmentStatus.PAID
                    "
                    class="appointment-details-view-options-menu-item py-3 px-4"
                    @click="toRescheduleAppointment"
                >
                    <div
                        class="appointment-details-view-options-menu-item__header mb-3 text-link-2"
                    >
                        Przełóż wizytę
                    </div>
                    <div class="appointment-details-view-options-menu-item__content text-link-3">
                        <CustomIcon
                            size="14"
                            name="info-circle"
                            class="mr-2"
                        />
                        <span>Możliwe do 24h przed terminem sesji</span>
                    </div>
                </div>
                <div
                    v-if="
                        auth_store.user.role === UserRole.USER &&
                        (selected_appointment.status === AppointmentStatus.UNPAID ||
                            (selected_appointment.status === AppointmentStatus.PAID &&
                                selected_appointment.start_time > Date.now()))
                    "
                    class="appointment-details-view-options-menu-item py-3 px-4"
                    @click="onAppointmentCancelRequest"
                >
                    <div
                        class="appointment-details-view-options-menu-item__header mb-3 text-link-2"
                    >
                        Anuluj wizytę
                    </div>
                    <div class="appointment-details-view-options-menu-item__content text-link-3">
                        <CustomIcon
                            size="14"
                            name="info-circle"
                            class="mr-2"
                        />
                        <span v-if="auth_store.user && auth_store.user.role === UserRole.USER">
                            Zwrot pieniędzy do 24h przed terminem sesji
                        </span>
                        <span v-else> Możliwe do 24h przed terminem sesji </span>
                    </div>
                </div>
            </div>
        </div>
        <!-- 2. MAIN -->
        <div
            v-if="auth_store.user && selected_appointment"
            class="appointment-details-view-dialog__main pa-6"
        >
            <!-- 2.1. MAIN USER TILE -->
            <UserTile
                v-if="auth_store.user.role === UserRole.USER"
                :name="
                    selected_appointment.host.first_name + ' ' + selected_appointment.host.last_name
                "
                :text="selected_appointment.host.therapist_info.job_title"
                :profile-image="
                    selected_appointment.host.profile_image
                        ? selected_appointment.host.profile_image
                        : undefined
                "
                class="mb-4"
                filled
                outlined
                @click:action-button="
                    () => {
                        if (selected_appointment) {
                            showTherapistDetails(selected_appointment.host._id);
                        }
                    }
                "
            />
            <UserTile
                v-else-if="
                    auth_store.user.role === UserRole.THERAPIST &&
                    selected_appointment.hosting_type === AppointmentHostingType.MINDGRAM &&
                    selected_appointment.mindgram
                "
                :name="
                    selected_appointment.mindgram.patient_first_name +
                    ' ' +
                    selected_appointment.mindgram.patient_last_name
                "
                text="Umawiający"
                :profile-image="
                    selected_appointment_owner?.profile_image
                        ? selected_appointment_owner?.profile_image
                        : undefined
                "
                class="mb-4"
                :action-button="false"
                filled
                outlined
            />
            <UserTile
                v-else-if="auth_store.user.role === UserRole.THERAPIST"
                :name="
                    selected_appointment_owner?.first_name +
                    ' ' +
                    selected_appointment_owner?.last_name
                "
                text="Umawiający"
                :profile-image="
                    selected_appointment_owner?.profile_image
                        ? selected_appointment_owner?.profile_image
                        : undefined
                "
                class="mb-4"
                :action-button="false"
                filled
                outlined
            />

            <!-- 2.2. APPOINTMENT DETAILS -->
            <div class="appointment-details-view text-link-2">
                <div class="appointment-details-view-item py-3 px-4">
                    <div class="appointment-details-view-item__col">Data i godzina:</div>
                    <div class="appointment-details-view-item__col text-right">
                        {{
                            moment(selected_appointment.start_time)
                                .tz(auth_store.getUserTimezone())
                                .format("D MMM YYYY, HH:mm")
                        }}
                    </div>
                </div>

                <div class="appointment-details-view-item py-3 px-4">
                    <div class="appointment-details-view-item__col">Usługa:</div>
                    <div class="appointment-details-view-item__col text-right">
                        {{ selected_appointment.product.name }}
                    </div>
                </div>

                <div
                    class="appointment-details-view-item py-3 px-4"
                    v-if="selected_appointment.product.type === ProductType.APPOINTMENT"
                >
                    <div class="appointment-details-view-item__col">Język terapii:</div>
                    <div class="appointment-details-view-item__col text-right">
                        {{
                            TherapySessionLanguageLabel[
                                selected_appointment.product.appointment.therapy_session.lang
                            ]
                        }}
                    </div>
                </div>

                <div
                    v-if="selected_appointment.product.appointment.participant_count > 1"
                    class="appointment-details-view-item py-3 px-4"
                >
                    <div class="appointment-details-view-item__col">
                        <div
                            v-if="auth_store.user.role === UserRole.USER"
                            class="mr-auto"
                        >
                            Uczestnicy:
                        </div>
                        <div
                            v-else
                            class="mr-auto"
                        >
                            Pozostali uczestnicy:
                        </div>
                    </div>
                    <div class="appointment-details-view-item__col text-right">
                        <div v-if="auth_store.user.role === UserRole.USER">
                            <div
                                class="mb-2"
                                v-for="user in selected_appointment.participants"
                            >
                                {{
                                    user.first_name +
                                    " " +
                                    user.last_name +
                                    (user._id === auth_store.user._id ? " (Ty)" : "")
                                }}
                            </div>
                        </div>
                        <div v-else>
                            {{
                                selected_appointment.participants.length > 1
                                    ? selected_appointment.participants
                                          .map(user => user.first_name + " " + user.last_name)
                                          .slice(1)
                                          .join(", ")
                                    : "Brak"
                            }}
                        </div>
                        <RisifyButton
                            v-if="
                                auth_store.user._id === selected_appointment.owner &&
                                (selected_appointment.status === AppointmentStatus.UNPAID ||
                                    selected_appointment.status === AppointmentStatus.PAID)
                            "
                            class="ml-4"
                            text
                            color="orange"
                            @click="
                                () => {
                                    if (selected_appointment) {
                                        participants_update_dialog = true;
                                        selected_participants = selected_appointment.participants
                                            .map(p => p._id)
                                            .filter(p => p != selected_appointment?.owner);
                                        selected_participants_local =
                                            selected_appointment.participants.filter(
                                                p => p._id != selected_appointment?.owner
                                            );
                                    }
                                }
                            "
                            small
                        >
                            Zarządzaj
                        </RisifyButton>
                    </div>
                </div>

                <div class="appointment-details-view-item py-3 px-4">
                    <div class="appointment-details-view-item__col">Czas trwania:</div>
                    <div class="appointment-details-view-item__col text-right">
                        {{
                            formatMinutesAsHumanizedDuration(
                                selected_appointment.product.appointment.duration
                            )
                        }}
                    </div>
                </div>

                <div
                    v-if="
                        selected_appointment.price &&
                        auth_store.user._id === selected_appointment.owner
                    "
                    class="appointment-details-view-item py-3 px-4"
                >
                    <div class="appointment-details-view-item__col">Cena:</div>
                    <div
                        class="appointment-details-view-item__col appointment-details-view-item__col--fcenter text-right"
                    >
                        {{
                            formatPrice(
                                selected_appointment.price.amount,
                                selected_appointment.price.currency
                            )
                        }}
                        <RisifyTooltip
                            v-if="
                                selected_appointment.price.amount === 0 &&
                                selected_appointment.price.npr &&
                                auth_store.user &&
                                auth_store.user.role === UserRole.USER
                            "
                            class="ml-1 text-center"
                            max-width="360px"
                        >
                            <template #content>
                                {{
                                    AppointmentPriceNoPaymentReasonLabel[
                                        selected_appointment.price.npr
                                    ]
                                }}
                            </template>
                            <template #activator>
                                <CustomIcon
                                    name="info-circle-outline"
                                    size="16"
                                    class="ml-1"
                                ></CustomIcon>
                            </template>
                        </RisifyTooltip>
                    </div>
                </div>

                <div class="appointment-details-view-item py-3 px-4">
                    <div class="appointment-details-view-item__col">Status:</div>
                    <div class="appointment-details-view-item__col text-right">
                        {{ AppointmentStatusLabel[selected_appointment.status] }}
                    </div>
                </div>

                <div
                    v-if="auth_store.user.role === UserRole.THERAPIST"
                    class="appointment-details-view-item py-3 px-4"
                >
                    <div class="appointment-details-view-item__col">E-mail:</div>
                    <div class="appointment-details-view-item__col text-right text-break">
                        {{ patient_email }}
                    </div>
                </div>

                <div
                    v-if="
                        auth_store.user.role === UserRole.THERAPIST &&
                        selected_appointment.hosting_type !== AppointmentHostingType.MINDGRAM
                    "
                    class="appointment-details-view-item py-3 px-4"
                >
                    <div class="appointment-details-view-item__col">Tel:</div>
                    <div class="appointment-details-view-item__col text-right text-break">
                        {{ patient_phone }}
                    </div>
                </div>
            </div>

            <!-- 2.3. PARTICIPANTS THID DETAILS -->
            <div
                class="appointment-details-thid mt-8"
                v-if="
                    auth_store.user.role === UserRole.THERAPIST &&
                    selected_appointment.hosting_type === AppointmentHostingType.RISIFY_SELF_HOST
                "
            >
                <h4 class="text-h7 mb-3">
                    Informacje o
                    {{
                        selected_appointment.participants.length > 1
                            ? "uczestnikach"
                            : "umawiającym"
                    }}
                </h4>
                <RisifySelect
                    v-if="selected_appointment.participants.length > 1"
                    outlined
                    :items="
                        selected_appointment.participants.map(it => ({
                            ...it,
                            text: it.first_name + ' ' + it.last_name,
                            value: it._id
                        }))
                    "
                    v-model="thid_view_participant"
                    dense
                    class-name="mb-2"
                >
                    <template #selection="{ slotProps }">
                        <UserTile
                            dense
                            :profile-image="slotProps.items[0].profile_image"
                            :name="
                                slotProps.items[0].first_name + ' ' + slotProps.items[0].last_name
                            "
                            :text="
                                slotProps.items[0]._id == selected_appointment.owner
                                    ? 'Umawiający'
                                    : 'Uczestnik'
                            "
                            :action-button="false"
                        />
                    </template>
                    <template #item="{ slotProps }">
                        <UserTile
                            dense
                            :profile-image="slotProps.item.profile_image"
                            :name="slotProps.item.first_name + ' ' + slotProps.item.last_name"
                            :text="
                                slotProps.item._id == selected_appointment.owner
                                    ? 'Umawiający'
                                    : 'Uczestnik'
                            "
                            :action-button="false"
                        />
                    </template>
                </RisifySelect>

                <div
                    class="appointment-details-view text-link-2"
                    v-if="
                        thid_view_expanded_participant && thid_view_expanded_participant.user_thid
                    "
                >
                    <div class="appointment-details-view-item py-3 px-4">
                        <div class="appointment-details-view-item__col">Płeć:</div>
                        <div class="appointment-details-view-item__col text-right">
                            {{
                                UserGenderLabel[
                                    thid_view_expanded_participant.user_thid.client_gender
                                ]
                            }}
                        </div>
                    </div>
                    <div class="appointment-details-view-item py-3 px-4">
                        <div class="appointment-details-view-item__col">Grupa wiekowa:</div>
                        <div class="appointment-details-view-item__col text-right">
                            {{
                                UserClientAgeGroupLabel[
                                    thid_view_expanded_participant.user_thid.client_age_group
                                ]
                            }}
                        </div>
                    </div>
                    <div class="appointment-details-view-item py-3 px-4">
                        <div class="appointment-details-view-item__col">Pierwsza terapia:</div>
                        <div class="appointment-details-view-item__col text-right">
                            {{
                                UserClientFirstTherapyLabel[
                                    thid_view_expanded_participant.user_thid.client_first_therapy
                                ]
                            }}
                        </div>
                    </div>
                    <div class="appointment-details-view-item py-3 px-4">
                        <div class="appointment-details-view-item__col">Typ terapii:</div>
                        <div class="appointment-details-view-item__col text-right">
                            {{
                                !thid_view_expanded_participant.user_thid.therapy_type ||
                                thid_view_expanded_participant.user_thid.therapy_type === "unknown"
                                    ? "Brak danych"
                                    : UserTherapistTherapyTypeLabel[
                                          thid_view_expanded_participant.user_thid.therapy_type
                                      ]
                            }}
                        </div>
                    </div>
                    <div class="appointment-details-view-item py-3 px-4">
                        <div class="appointment-details-view-item__col">Problemy:</div>
                        <div class="appointment-details-view-item__col text-right">
                            {{
                                thid_view_expanded_participant.user_thid.client_problems.length > 0
                                    ? thid_view_expanded_participant.user_thid.client_problems
                                          .map(it => {
                                              if (
                                                  Object.keys(
                                                      UserTherapistSpecializationLabel
                                                  ).includes(it)
                                              ) {
                                                  return UserTherapistSpecializationLabel[
                                                      it as UserTherapistSpecialization
                                                  ];
                                              }
                                              return it;
                                          })
                                          .join(", ")
                                    : "Brak danych"
                            }}
                        </div>
                    </div>
                    <div
                        class="appointment-details-view-item py-3 px-4"
                        style="flex-wrap: wrap"
                    >
                        <div
                            class="appointment-details-view-item__col mb-1"
                            style="width: 100; flex: initial"
                        >
                            Opis szczegółowy:
                        </div>
                        <div
                            class="appointment-details-view-item__col"
                            style="width: 100%; flex: initial"
                        >
                            {{
                                thid_view_expanded_participant.user_thid.client_problems_description
                                    ? thid_view_expanded_participant.user_thid
                                          .client_problems_description
                                    : "-"
                            }}
                        </div>
                    </div>
                    <div class="appointment-details-view-item py-3 px-4">
                        <div class="appointment-details-view-item__col">Język terapii:</div>
                        <div class="appointment-details-view-item__col text-right">
                            {{
                                TherapySessionLanguageLabel[
                                    thid_view_expanded_participant.user_thid.therapy_language
                                ]
                            }}
                        </div>
                    </div>
                    <div class="appointment-details-view-item py-3 px-4">
                        <div class="appointment-details-view-item__col">Płeć terapeuty:</div>
                        <div class="appointment-details-view-item__col text-right">
                            {{
                                thid_view_expanded_participant.user_thid.therapist_gender ===
                                UserGender.UNKNOWN
                                    ? "Bez znaczenia"
                                    : UserGenderLabel[
                                          thid_view_expanded_participant.user_thid.therapist_gender
                                      ]
                            }}
                        </div>
                    </div>
                    <div class="appointment-details-view-item py-3 px-4">
                        <div class="appointment-details-view-item__col">Nurty terapeutyczne:</div>
                        <div class="appointment-details-view-item__col text-right">
                            {{
                                thid_view_expanded_participant.user_thid.therapy_directions.length >
                                0
                                    ? thid_view_expanded_participant.user_thid.therapy_directions
                                          .map(it => UserTherapistTherapyDirectionLabel[it])
                                          .join(", ")
                                    : "Brak danych"
                            }}
                        </div>
                    </div>
                    <div class="appointment-details-view-item py-3 px-4">
                        <div class="appointment-details-view-item__col">Godziny terapii:</div>
                        <div class="appointment-details-view-item__col text-right">
                            {{
                                thid_view_expanded_participant.user_thid.therapy_hours.length > 0
                                    ? thid_view_expanded_participant.user_thid.therapy_hours
                                          .map(it => UserPreferredTherapyHourLabel[it])
                                          .join(", ")
                                    : "Brak danych"
                            }}
                        </div>
                    </div>
                </div>
                <div
                    class="appointment-details-view-thid__nodata text-link-2 mt-2"
                    v-else
                >
                    Brak danych do wyświetlenia
                </div>
            </div>

            <!-- 2.4. INFORMACJA O WIZYCIE MINDGRAM -->
            <div
                class="appointment-details-thid mt-8"
                v-if="
                    auth_store.user.role === UserRole.THERAPIST &&
                    selected_appointment.hosting_type === AppointmentHostingType.MINDGRAM
                "
            >
                <div class="appointment-details-view__mindgram">
                    <div class="text-body-2">
                        Wizyta została umówiona przez zewnętrznego partnera:
                        <img src="../assets/imgs/mindgram-logo.svg" />
                        i będzie prowadzona poza aplikacją Risify.
                    </div>
                </div>
            </div>
            <!-- 2.5. INFORMACJA O WIZYCIE VCITA -->
            <div
                class="appointment-details-thid mt-8"
                v-if="
                    auth_store.user.role === UserRole.THERAPIST &&
                    selected_appointment.hosting_type === AppointmentHostingType.VCITA_MIGRATION
                "
            >
                <div class="appointment-details-view__mindgram">
                    <div class="text-body-2">
                        Wizyta archiwalna pochodząca z poprzedniego systemu rezerwacji wizyt.
                    </div>
                </div>
            </div>
        </div>
        <!-- 3. FOOTER -->
        <div
            v-if="auth_store.user && selected_appointment"
            class="appointment-details-view-dialog__footer"
        >
            <div class="appointment-details-view-dialog__actions">
                <div
                    v-if="
                        enable_start_appointment_button === false &&
                        (selected_appointment.status === AppointmentStatus.PAID ||
                            selected_appointment.status === AppointmentStatus.IN_PROGRESS)
                    "
                    class="text-center text-label-3 mb-n3"
                >
                    Link do&nbsp;spotkania będzie aktywny na&nbsp;15 minut przed
                    rozpoczęciem&nbsp;sesji
                </div>
                <!-- MAIN CTA -->
                <!-- 1. USER -->
                <template v-if="auth_store.user.role === UserRole.USER">
                    <RisifyButton
                        v-if="
                            selected_appointment.status === AppointmentStatus.UNPAID &&
                            selected_appointment.owner === auth_store.user._id
                        "
                        color="orange"
                        block
                        @click="redirectToPayment"
                    >
                        <CustomIcon
                            class="mr-2"
                            name="cart"
                        />
                        Opłać wizytę
                    </RisifyButton>
                    <RisifyButton
                        v-else-if="
                            selected_appointment.status === AppointmentStatus.PAID ||
                            selected_appointment.status === AppointmentStatus.IN_PROGRESS
                        "
                        color="green"
                        block
                        @click="beginAppointment"
                        :disabled="enable_start_appointment_button === false"
                    >
                        <CustomIcon
                            class="mr-2"
                            name="record"
                        />
                        Dołącz do spotkania
                    </RisifyButton>
                </template>
                <!-- 2. TERAPEUTA -->
                <template v-else-if="auth_store.user.role === UserRole.THERAPIST">
                    <RisifyButton
                        v-if="
                            selected_appointment.status === AppointmentStatus.PAID ||
                            selected_appointment.status === AppointmentStatus.IN_PROGRESS
                        "
                        color="green"
                        block
                        @click="beginAppointment"
                        :disabled="enable_start_appointment_button === false"
                    >
                        <CustomIcon
                            class="mr-2"
                            name="record"
                        />
                        {{
                            selected_appointment.status === AppointmentStatus.PAID
                                ? "Rozpocznij spotkanie"
                                : "Dołącz do spotkania"
                        }}
                    </RisifyButton>
                    <RisifyButton
                        v-if="
                            selected_appointment.status === AppointmentStatus.IN_PROGRESS &&
                            show_complete_appointment_button
                        "
                        color="green"
                        block
                        @click="end_appointment_dialog = true"
                    >
                        Zakończ wizytę
                    </RisifyButton>
                </template>
                <RisifyButton
                    v-if="ths_feedback_possible"
                    color="dark-green"
                    outlined
                    block
                    :loading="survey_loading"
                    @click="onDisplayThsFeedback(false)"
                >
                    Oceń wizytę
                </RisifyButton>
                <RisifyButton
                    v-if="
                        selected_appointment.status !== AppointmentStatus.UNPAID &&
                        selected_appointment.status !== AppointmentStatus.CANCELED
                    "
                    color="dark-green"
                    outlined
                    block
                    :loading="appointment_chat_loading"
                    @click="
                        () => {
                            const fchat =
                                selected_appointment &&
                                chats_store.findChat(
                                    selected_appointment.owner,
                                    selected_appointment.host._id,
                                    selected_appointment.product._id
                                );
                            if (fchat) {
                                router.push({
                                    name: 'chat',
                                    params: {
                                        id: fchat._id
                                    }
                                });
                                emitter.emit('AppointmentDetailsView::close');
                            } else {
                                toChat();
                            }
                        }
                    "
                >
                    <CustomIcon
                        class="mr-2"
                        name="chat"
                    />
                    {{
                        auth_store.user.role === UserRole.USER
                            ? "Napisz do terapeuty"
                            : "Porozmawiaj z pacjentami"
                    }}
                </RisifyButton>
            </div>
        </div>
    </FullscreenOverlay>

    <!-- 4. THERAPIST - CREATE APPOINTMENT DIALOG -->
    <CreateAppointmentDialog
        v-model="appointment_create_dialog"
        :product="selected_appointment.product._id"
        :owner="selected_appointment_owner"
        :participants="
            selected_appointment.participants.filter(p => p._id != selected_appointment?.owner)
        "
        :key="selected_appointment?._id"
        v-if="selected_appointment"
    />

    <!-- 5. IMPOSSIBLE APPOINTMENT RESCHEDULE DIALOG -->
    <Dialog
        v-model="appointment_cannot_reschedule_dialog"
        persistent
    >
        <template v-slot:header>
            <DialogBaseHeader> Brak możliwości przełożenia wizyty </DialogBaseHeader>
        </template>
        <template v-slot:default>
            <DialogBaseBody v-if="selected_appointment">
                <div class="text-body-1">
                    <p>
                        Przełożenie wizyty możliwe&nbsp;jest do&nbsp;24 godzin przed datą
                        jej&nbsp;planowanego rozpoczęcia:
                    </p>
                    <p class="my-2">
                        <b>{{
                            capitalizeString(
                                moment(selected_appointment.start_time)
                                    .tz(auth_store.getUserTimezone())
                                    .format("ddd, DD.MM.YYYY HH:mm")
                            )
                        }}</b>
                    </p>
                    <div v-if="auth_store.user && auth_store.user.role === UserRole.THERAPIST">
                        <p>
                            Jeżeli nie&nbsp;możesz przeprowadzić wizyty w&nbsp;umówionym terminie,
                            skontaktuj&nbsp;się z&nbsp;Biurem Obsługi Klienta Risify:
                        </p>
                        <p class="mt-2">
                            <a
                                href="tel:+48882022552"
                                style="text-decoration: none; color: inherit"
                            >
                                <b>+48 882 022 552</b> (w godzinach pracy biura)
                            </a>
                        </p>
                        <p class="mt-1">
                            <a
                                href="mailto:pomoc@risify.pl"
                                style="text-decoration: none; color: inherit"
                            >
                                <b>pomoc@risify.pl</b>
                            </a>
                        </p>
                    </div>
                </div>
            </DialogBaseBody>
        </template>
        <template v-slot:footer>
            <DialogBaseFooter>
                <RisifyButton
                    color="yellow"
                    class="ml-auto"
                    @click="appointment_cannot_reschedule_dialog = false"
                >
                    Zamknij
                </RisifyButton>
            </DialogBaseFooter>
        </template>
    </Dialog>

    <!-- 6. THERAPIST RESCHEDULE APPOINTMENT DIALOG -->
    <Dialog
        v-model="appointment_treschedule_dialog"
        v-if="selected_appointment"
        persistent
    >
        <template v-slot:header>
            <DialogBaseHeader> Przełożenie wizyty </DialogBaseHeader>
        </template>
        <template v-slot:default>
            <DialogBaseBody>
                <AppointmentSimpleSlotOrDatetimePicker
                    :product="selected_appointment.product._id"
                    :therapist="selected_appointment.host._id"
                    :duration="selected_appointment.product.appointment.duration"
                    ref="date_selector_ref"
                />
                <RisifyTextarea
                    id="reason"
                    label="Powód przełożenia"
                    placeholder="Wpisz powód..."
                    show-asterisk
                    :rows="4"
                    v-model="appointment_reschedule_reason"
                    ref="reschedule_reason_txtarea_ref"
                    :rules="[isRequired, minMax(1, 255)]"
                    :disabled="appointment_reschedule_loading"
                    class-name="my-4 my-md-6"
                    outlined
                ></RisifyTextarea>
            </DialogBaseBody>
        </template>
        <template v-slot:footer>
            <DialogBaseFooter>
                <RisifyButton
                    text
                    color="dark-green"
                    :class="{
                        'mr-auto': is_more_or_equal_than_24_hours
                    }"
                    :disabled="appointment_reschedule_loading"
                    @click="
                        appointment_treschedule_dialog = false;
                        appointment_reschedule_reason = '';
                    "
                >
                    Anuluj
                </RisifyButton>
                <RisifyButton
                    color="yellow"
                    class="ml-auto"
                    @click="confirmRescheduleAppointment"
                    :loading="appointment_reschedule_loading"
                >
                    Zatwierdź
                </RisifyButton>
            </DialogBaseFooter>
        </template>
    </Dialog>

    <!-- 7. CANCEL APPOINTMENT DIALOG -->
    <Dialog
        v-model="appointment_cancel_dialog"
        v-if="selected_appointment"
        persistent
    >
        <template v-slot:header>
            <DialogBaseHeader>
                Czy jesteś pewien, że chcesz anulować wizytę?
                <div class="appointment-details-view-cancel-dialog__subtitle mt-3 text-link-2">
                    <div>
                        {{
                            capitalizeString(
                                moment(selected_appointment.start_time)
                                    .tz(auth_store.getUserTimezone())
                                    .format("dddd, D MMM YYYY, HH:mm")
                            )
                        }}

                        <span
                            v-if="auth_store.user && auth_store.user.role === UserRole.USER"
                            class="text-weight-semibold"
                        >
                            &middot;
                            {{
                                is_more_or_equal_than_24_hours ? "Zwrot 100%" : "Brak zwrotu"
                            }}</span
                        >
                    </div>
                    <RisifyTooltip
                        v-if="auth_store.user && auth_store.user.role === UserRole.USER"
                        class="ml-1 mt-1"
                        position="bottom"
                        max-width="360px"
                    >
                        <template v-slot:activator>
                            <CustomIcon
                                name="info-circle"
                                color="dark-green"
                                size="16"
                            />
                        </template>
                        <template v-slot:content>
                            {{
                                is_more_or_equal_than_24_hours
                                    ? "Wizyta wróci na Twoje konto w formie przedpłaty"
                                    : "Zgodnie z regulaminem nie przysługuje zwrot za anulowanie wizyty w czasie poniżej 24h przed rozpoczęciem"
                            }}
                        </template>
                    </RisifyTooltip>
                </div>
            </DialogBaseHeader>
        </template>
        <template v-slot:default>
            <DialogBaseBody>
                <template
                    v-if="
                        selected_appointment.hosting_type ===
                            AppointmentHostingType.RISIFY_SELF_HOST ||
                        (auth_store.user && auth_store.user.role === UserRole.USER)
                    "
                >
                    <RisifyTextarea
                        id="reason"
                        label="Powód anulowania"
                        placeholder="Wpisz powód..."
                        show-asterisk
                        :rows="4"
                        v-model="appointment_cancel_reason"
                        ref="cancel_reason_txtarea_ref"
                        :rules="[isRequired, minMax(1, 255)]"
                        :disabled="appointment_cancel_loading"
                        class-name="mb-4 mb-md-6"
                        outlined
                    ></RisifyTextarea>
                </template>
                <template
                    v-else-if="
                        selected_appointment.hosting_type === AppointmentHostingType.MINDGRAM
                    "
                >
                    <RisifySelect
                        outlined
                        label="Powód anulowania"
                        v-model="appointment_cancel_mindgram_reason"
                        :items="
                            Object.entries(MindgramAppointmentCancellationReasonLabel).map(it => ({
                                text: it[1],
                                value: it[0]
                            }))
                        "
                        class-name="mb-4"
                        show-asterisk
                        :disabled="appointment_cancel_loading"
                    />
                    <RisifyTextarea
                        outlined
                        label="Powód anulowania"
                        placeholder="Wpisz powód..."
                        show-asterisk
                        :rows="2"
                        v-model="appointment_cancel_mindgram_reason_other"
                        ref="appointment_cancel_mindgram_reason_other_ref"
                        :rules="[isRequired, minMax(1, 255)]"
                        :disabled="appointment_cancel_loading"
                        class-name="mb-4"
                        v-if="
                            appointment_cancel_mindgram_reason ===
                            MindgramAppointmentCancellationReason.SPECIALIST_OTHER
                        "
                    />
                    <RisifySelect
                        outlined
                        label="Rekomendacja dla Pacjenta"
                        v-model="appointment_cancel_mindgram_recommendation"
                        :items="
                            Object.entries(
                                MindgramAppointmentCancellationUserRecommendationLabel
                            ).map(it => ({
                                text: it[1],
                                value: it[0]
                            }))
                        "
                        class-name="mb-4"
                        show-asterisk
                        :disabled="appointment_cancel_loading"
                    />
                    <RisifyTextarea
                        outlined
                        label="Dodatkowe informacje dla Pacjenta"
                        placeholder="Np. proponowany nowy termin wizyty"
                        show-asterisk
                        :rows="2"
                        v-model="appointment_cancel_mindgram_recommendation_note"
                        ref="appointment_cancel_mindgram_recommendation_note_ref"
                        :rules="[isRequired, minMax(1, 255)]"
                        :disabled="appointment_cancel_loading"
                    />
                </template>
            </DialogBaseBody>
        </template>
        <template v-slot:footer>
            <DialogBaseFooter
                v-if="auth_store.user && auth_store.user.role === UserRole.USER"
                class="appointment-details-view-cancel-dialog__footer"
            >
                <RisifyButton
                    v-if="is_more_or_equal_than_24_hours && !is_mobile"
                    text
                    color="dark-green"
                    class="mr-auto"
                    @click="
                        if (selected_appointment) {
                            router.push({
                                name: 'wiz-fn1-appointment-reschedule',
                                query: { appointment: selected_appointment._id }
                            });
                            appointment_cancel_dialog = false;
                            appointment_details_dialog = false;
                        }
                    "
                    :disabled="appointment_cancel_loading"
                >
                    Przełóż termin
                </RisifyButton>
                <RisifyButton
                    @click="appointment_cancel_dialog = false"
                    :disabled="appointment_cancel_loading"
                    text
                    color="dark-green"
                >
                    Nie
                </RisifyButton>
                <RisifyButton
                    color="red"
                    class="ml-4"
                    @click="
                        () => {
                            if (!is_more_or_equal_than_24_hours) {
                                if (
                                    cancel_reason_txtarea_ref &&
                                    cancel_reason_txtarea_ref.validate()
                                ) {
                                    appointment_cancel_confirm_dialog = true;
                                }
                                return;
                            } else {
                                cancelAppointment();
                            }
                        }
                    "
                    :loading="appointment_cancel_loading"
                >
                    Anuluj wizytę
                </RisifyButton>
            </DialogBaseFooter>
            <DialogBaseFooter
                v-else
                class="appointment-details-view-cancel-dialog__footer"
            >
                <RisifyButton
                    @click="appointment_cancel_dialog = false"
                    :disabled="appointment_cancel_loading"
                    text
                    color="dark-green"
                >
                    Nie
                </RisifyButton>
                <RisifyButton
                    color="red"
                    class="ml-auto"
                    @click="cancelAppointment"
                    :loading="appointment_cancel_loading"
                >
                    Anuluj wizytę
                </RisifyButton>
            </DialogBaseFooter>
        </template>
    </Dialog>

    <!-- 8. CANCEL CONFIRM APPOINTMENT DIALOG -->
    <Dialog
        v-model="appointment_cancel_confirm_dialog"
        persistent
        width="480"
    >
        <template v-slot:header>
            <DialogBaseHeader> Potwierdzenie anulowania wizyty </DialogBaseHeader>
        </template>
        <template v-slot:default>
            <DialogBaseBody>
                Do Twojej wizyty pozostało już mniej niż 24h - zgodnie z regulaminem
                <span class="text-weight-bold">nie otrzymasz zwrotu środków </span> za tę wizytę
                jeżeli ją teraz anulujesz. Czy na pewno chcesz ją anulować?
            </DialogBaseBody>
        </template>
        <template v-slot:footer>
            <DialogBaseFooter class="appointment-details-view-cancel-dialog__footer">
                <RisifyButton
                    @click="
                        appointment_cancel_confirm_dialog = false;
                        appointment_cancel_dialog = false;
                        appointment_cancel_reason = '';
                    "
                    :disabled="appointment_cancel_loading"
                    text
                    color="dark-green"
                >
                    Nie
                </RisifyButton>
                <RisifyButton
                    color="red"
                    class="ml-auto"
                    @click="cancelAppointment"
                    :loading="appointment_cancel_loading"
                >
                    Tak, anuluj
                </RisifyButton>
            </DialogBaseFooter>
        </template>
    </Dialog>

    <!-- 9. PARTICIPANTS DIALOG -->
    <Dialog
        persistent
        v-model="participants_dialog"
    >
        <template v-slot:header>
            <DialogBaseHeader> Pozostali uczestnicy </DialogBaseHeader>
        </template>
        <template v-slot:default>
            <DialogBaseBody>
                <RisifySelect
                    v-if="auth_store.user && auth_store.user.role === UserRole.USER"
                    v-model="selected_participants"
                    :items="participants_items"
                    :disabled="auth_store.user.linked_users.length < 1"
                    label="Wybierz uczestników"
                    :placeholder="
                        auth_store.user.linked_users.length
                            ? 'Wybierz osoby'
                            : 'Brak skojarzonych użytkowników'
                    "
                    :rules="[
                        v => {
                            if (
                                !Array.isArray(v) ||
                                !selected_appointment?.product.appointment.participant_count
                            )
                                return true;
                            if (
                                v.length >=
                                selected_appointment?.product.appointment.participant_count
                            )
                                return `Maksymalna liczba dodatkowych uczestników: ${
                                    selected_appointment?.product.appointment.participant_count - 1
                                }`;
                            return true;
                        }
                    ]"
                    ref="participants_select_ref"
                    :multiple="
                        (auth_store.user.linked_users.length > 1 &&
                        selected_appointment?.product.appointment.participant_count &&
                        selected_appointment?.product.appointment.participant_count > 2) as boolean
                    "
                    outlined
                >
                </RisifySelect>
            </DialogBaseBody>
        </template>
        <template v-slot:footer>
            <DialogBaseFooter>
                <RisifyButton
                    color="dark-green"
                    text
                    @click="
                        participants_dialog = false;
                        participants_update_dialog = true;
                    "
                >
                    Anuluj
                </RisifyButton>
                <RisifyButton
                    color="yellow"
                    @click="addParticipants"
                >
                    Dodaj
                </RisifyButton>
            </DialogBaseFooter>
        </template>
    </Dialog>

    <!-- 10. PARTICIPANTS UPDATE DIALOG -->
    <Dialog
        persistent
        v-model="participants_update_dialog"
    >
        <template v-slot:header>
            <DialogBaseHeader
                tabs
                :tabs-items="[
                    { text: 'Zarządzaj', value: 0 },
                    { text: 'Zaproś', value: 1 }
                ]"
                v-model:modelTab="part_dialog_tab"
            >
                Pozostali uczestnicy
            </DialogBaseHeader>
        </template>
        <template v-slot:default>
            <DialogBaseBody>
                <div
                    v-if="
                        part_dialog_tab == 0 &&
                        selected_appointment &&
                        selected_appointment.product.appointment.participant_count > 1 &&
                        auth_store.user &&
                        auth_store.user.role === UserRole.USER
                    "
                >
                    <div
                        class="appointment-details-view-sparticipant__header text-link-2 mt-6 mt-md-0 mb-2"
                    >
                        Aktualna liczba dodatkowych uczestników:
                        {{
                            `(${selected_participants_local.length}/${
                                selected_appointment.product.appointment.participant_count - 1
                            })`
                        }}
                        <RisifyButton
                            v-if="
                                selected_participants_local.length <
                                selected_appointment.product.appointment.participant_count - 1
                            "
                            color="orange"
                            text
                            @click="
                                () => {
                                    participants_update_dialog = false;
                                    participants_dialog = true;
                                }
                            "
                            >Dodaj</RisifyButton
                        >
                    </div>
                    <UserTile
                        :profile-image="user.profile_image"
                        :name="user.first_name + ' ' + user.last_name"
                        v-for="user in selected_participants_local"
                        :key="user._id"
                        outlined
                    >
                        <template #action-slot>
                            <RisifyButton
                                text
                                icon
                                color="orange"
                                @click="
                                    () => {
                                        selected_participants_local =
                                            selected_participants_local.filter(
                                                it => it._id !== user._id
                                            );
                                    }
                                "
                                small
                                ><CustomIcon name="close"
                            /></RisifyButton>
                        </template>
                    </UserTile>
                    <div
                        v-if="selected_participants_local.length === 0"
                        class="text-link-2"
                    >
                        Nie wybrano dodatkowych uczestników
                    </div>
                </div>
                <RisifyTextField
                    v-else-if="part_dialog_tab == 1"
                    v-model="participant_email"
                    label="Adres e-mail"
                    placeholder="Wpisz adres e-mail"
                    show-asterisk
                    :rules="[isRequired, isEmail]"
                    :disabled="send_invitation_loading"
                    ref="participant_email_ref"
                    @keydown.enter="sendInvitation"
                    autofocus
                    outlined
                />
            </DialogBaseBody>
        </template>
        <template v-slot:footer>
            <DialogBaseFooter>
                <RisifyButton
                    color="dark-green"
                    text
                    @click="participants_update_dialog = false"
                >
                    Anuluj
                </RisifyButton>
                <RisifyButton
                    color="yellow"
                    :loading="participants_update_loading"
                    @click="updateParticipants"
                    v-if="part_dialog_tab == 0"
                >
                    Zapisz
                </RisifyButton>
                <RisifyButton
                    color="yellow"
                    :loading="send_invitation_loading"
                    @click="sendInvitation"
                    v-if="part_dialog_tab == 1"
                >
                    Wyślij
                </RisifyButton>
            </DialogBaseFooter>
        </template>
    </Dialog>

    <!-- 11. END APPOINTMENT DIALOG -->
    <Dialog
        persistent
        v-model="end_appointment_dialog"
    >
        <template v-slot:header>
            <DialogBaseHeader> Zakończenie wizyty </DialogBaseHeader>
        </template>
        <template v-slot:default>
            <DialogBaseBody>
                Czy na pewno chcesz zakończyć tę wizytę? Spowoduje to zakończenie rozmowy wideo i
                rozliczenie wizyty.
            </DialogBaseBody>
        </template>
        <template v-slot:footer>
            <DialogBaseFooter>
                <RisifyButton
                    color="dark-green"
                    text
                    :disabled="end_appointment_dialog_loading"
                    @click="end_appointment_dialog = false"
                >
                    Anuluj
                </RisifyButton>
                <RisifyButton
                    color="yellow"
                    :loading="end_appointment_dialog_loading"
                    @click="endAppointment"
                >
                    Zakończ
                </RisifyButton>
            </DialogBaseFooter>
        </template>
    </Dialog>

    <!-- 12. CANNOT CANCEL DIALOG -->
    <Dialog
        v-model="appointment_cannot_cancel_dialog"
        persistent
    >
        <template v-slot:header>
            <DialogBaseHeader> Brak możliwości anulowania wizyty </DialogBaseHeader>
        </template>
        <template v-slot:default>
            <DialogBaseBody v-if="selected_appointment">
                <div class="text-body-1">
                    <p>
                        Anulowanie wizyty możliwe&nbsp;jest do&nbsp;24 godzin przed datą
                        jej&nbsp;planowanego rozpoczęcia:
                    </p>
                    <p class="my-2">
                        <b>{{
                            capitalizeString(
                                moment(selected_appointment.start_time)
                                    .tz(auth_store.getUserTimezone())
                                    .format("ddd, DD.MM.YYYY HH:mm")
                            )
                        }}</b>
                    </p>
                    <p>
                        Jeżeli nie&nbsp;możesz przeprowadzić wizyty w&nbsp;umówionym terminie,
                        skontaktuj&nbsp;się z&nbsp;Biurem Obsługi Klienta Risify:
                    </p>
                    <p class="mt-2">
                        <a
                            href="tel:+48882022552"
                            style="text-decoration: none; color: inherit"
                        >
                            <b>+48 882 022 552</b> (w godzinach pracy biura)
                        </a>
                    </p>
                    <p class="mt-1">
                        <a
                            href="mailto:pomoc@risify.pl"
                            style="text-decoration: none; color: inherit"
                        >
                            <b>pomoc@risify.pl</b>
                        </a>
                    </p>
                </div>
            </DialogBaseBody>
        </template>
        <template v-slot:footer>
            <DialogBaseFooter>
                <RisifyButton
                    color="yellow"
                    class="ml-auto"
                    @click="appointment_cannot_cancel_dialog = false"
                >
                    Zamknij
                </RisifyButton>
            </DialogBaseFooter>
        </template>
    </Dialog>

    <!-- 13. THS FEEDBACK DIALOG -->
    <Dialog
        v-if="survey"
        v-model="ths_feedback_dialog"
        ref="ths_feedback_dialog_ref"
    >
        <template v-slot:header>
            <DialogBaseHeader
                close-button
                @close="ths_feedback_dialog = false"
            >
                {{ survey.title }}
            </DialogBaseHeader>
        </template>
        <template v-slot:default>
            <DialogBaseBody>
                <div class="text-body-1">{{ survey.description }}</div>
                <SurveyQuestions
                    ref="survey_ref"
                    :survey="survey"
                />
            </DialogBaseBody>
        </template>
        <template v-slot:footer>
            <DialogBaseFooter>
                <RisifyButton
                    color="light-green"
                    class="ml-auto"
                    @click="sendThsFeedback"
                    :loading="ths_feedback_loading"
                >
                    Wyślij ocenę
                </RisifyButton>
            </DialogBaseFooter>
        </template>
    </Dialog>

    <!-- 13. AFFPROG INVITE DIALOG -->
    <Dialog
        v-model="affprog_invite_dialog"
        persistent
    >
        <template v-slot:default>
            <div class="text-link-2 text-md-body-2 px-6 pt-3 pt-md-6">
                <div class="text-h4 mb-4">
                    Cieszymy&nbsp;się, że&nbsp;wysoko oceniasz psychoterapię online w&nbsp;Risify!
                </div>
                <div class="mb-6">
                    Czy wiesz, że za&nbsp;polecenie Risify znajomym możesz otrzymać zniżkę
                    60&nbsp;zł na&nbsp;4 kolejne wizyty? Wystarczy, że Twój znajomy odbędzie jedną
                    sesję.
                </div>
                <div class="mb-2">Skopiuj link:</div>
                <div class="appointment-details-view-card pa-3">
                    <div class="appointment-details-view-card__link mr-2">
                        {{ affprog_invite_link }}
                    </div>
                    <RisifyButton
                        text
                        color="dark-green"
                        class="ml-auto"
                        small
                        @click="copyInvitationLink"
                    >
                        <CustomIcon
                            class="mr-1"
                            name="copy"
                        />
                        Kopiuj
                    </RisifyButton>
                </div>
                <div class="mt-2">i&nbsp;podziel&nbsp;się nim ze&nbsp;znajomymi.</div>
            </div>
        </template>
        <template v-slot:footer>
            <div class="appointment-details-view-affprog-dialog__footer pa-6">
                <div>
                    <a
                        class="appointment-details-view__text--link text-link-3 text-weight-medium"
                        :href="TOS_PDF_FILE"
                        target="_blank"
                        @click.stop
                    >
                        Regulamin promocji
                    </a>
                    <span class="text-link-3"> "Polecaj Znajomych" </span>
                </div>
                <RisifyButton
                    color="yellow"
                    class="my-4 my-sm-0"
                    @click="affprog_invite_dialog = false"
                >
                    Zamknij
                </RisifyButton>
            </div>
        </template>
    </Dialog>
</template>

<script setup lang="ts">
import { ref, computed, onMounted, onUnmounted } from "vue";
import { displayAlert } from "@/main";
import { emitter } from "@/plugins/eventEmitter";
import { Capacitor } from "@capacitor/core";
import router from "@/plugins/router";
import moment from "@/helpers/moment";

import { useAuthStore } from "@/stores/auth";
import { useChatsStore } from "@/stores/chats";
import { useFetchedElementsStore } from "@/stores/fetched-elements";
import { httpJsonRequest } from "@/plugins/fetchApi";

import AppointmentSimpleSlotOrDatetimePicker, {
    AppointmentSimpleSlotOrDatetimePickerExpose
} from "@/components/pickers/AppointmentSimpleSlotOrDatetimePicker.vue";
import CustomIcon from "@/components/generics/CustomIcon.vue";
import Dialog, { DialogExpose } from "@/components/dialogs/Dialog.vue";
import DialogBaseHeader from "@/components/dialogs/DialogBaseHeader.vue";
import DialogBaseBody from "@/components/dialogs/DialogBaseBody.vue";
import DialogBaseFooter from "@/components/dialogs/DialogBaseFooter.vue";
import FullscreenOverlay from "@/components/dialogs/FullscreenOverlay.vue";
import RisifyButton from "@/components/buttons/RisifyButton.vue";
import RisifySelect from "@/components/form-inputs/RisifySelect.vue";
import RisifyTextarea from "@/components/form-inputs/RisifyTextarea.vue";
import RisifyTextField from "@/components/form-inputs/RisifyTextField.vue";
import RisifyTooltip from "@/components/generics/RisifyTooltip.vue";
import SurveyQuestions, { SurveyQuestionsExpose } from "@/modules/survey/SurveyQuestions.vue";
import TopNavigation from "@/components/navigation/TopNavigation.vue";
import UserTile from "@/components/tiles/UserTile.vue";
import { RisifyInputBaseExpose } from "@/components/form-inputs/RisifyInput.vue";
import {
    RisifySelectOption,
    RisifySelectValue
} from "@/components/form-inputs/RisifySelectElement.vue";
import CreateAppointmentDialog from "@/modules/appointments/CreateAppointmentDialog.vue";

import {
    capitalizeString,
    formatMinutesAsHumanizedDuration,
    formatPrice
} from "@/helpers/formatters";
import { isEmail, isRequired, minMax } from "@/helpers/validators";
import { useBreakpoints } from "@/helpers/breakpoints";

import {
    Appointment,
    AppointmentStatus,
    AppointmentStatusLabel,
    ApiAppointmentResponse,
    AppointmentHostingType,
    MindgramAppointmentCancellationUserRecommendation,
    MindgramAppointmentCancellationReason,
    ApiAppointmentRescheduleResponse,
    AppointmentRescheduleFlow,
    AppointmentPriceNoPaymentReasonLabel
} from "@/types/appointment";
import {
    UserGenderLabel,
    UserRole,
    UserGender,
    UserClientAgeGroupLabel,
    UserClientFirstTherapyLabel,
    UserTherapistTherapyTypeLabel,
    UserTherapistSpecialization,
    UserTherapistSpecializationLabel,
    UserTherapistTherapyDirectionLabel,
    UserPreferredTherapyHourLabel,
    UserUser
} from "@/types/user";
import { ProductType } from "@/types/product";
import { TherapySessionLanguageLabel } from "@/types/general";
import { UserInviteAction } from "@/types/user-invite";
import { ApiChatResponse, ChatThusappr, ChatType } from "@/types/chat";
import { Stopwatch } from "@/helpers/waiters";
import { ApiSurveyResponse, Survey, SurveyQuestion, SurveyQuestionType } from "@/types/surveys";
import { ApiSuccessResponse } from "@/types/api-http-requests";
import { Base64 } from "js-base64";

import TOS_PDF_FILE from "@/assets/docs/regulamin-akcja-promocyjna-polecaj-znajomych-03072024.pdf";
import { APP_DOMAIN } from "@/constants";

/*###########
### SETUP ###
###########*/
const auth_store = useAuthStore();
const chats_store = useChatsStore();
const fetched_elements_store = useFetchedElementsStore();

/*#############
### GENERAL ###
############ */
const { is_mobile } = useBreakpoints();
const appointment_options_menu = ref(false);

/*#########################
### APPOINTMENT DETAILS ###
######################## */
const appointment_details_dialog = ref(false);
const selected_appointment = ref<Appointment>();

const thid_view_participant = ref<string>("");

const current_time_update_interval = ref<NodeJS.Timeout | null>();
const current_time = ref(Date.now());
function clearCurrentTimeInterval() {
    if (current_time_update_interval.value) {
        clearInterval(current_time_update_interval.value);
    }
}

const thid_view_expanded_participant = computed(() => {
    if (!selected_appointment.value) return undefined;

    return selected_appointment.value.participants.find(
        it => it._id == thid_view_participant.value
    );
});
const selected_appointment_owner = computed(() => {
    return selected_appointment.value?.participants.find(
        p => p._id == selected_appointment.value?.owner
    );
});
const enable_start_appointment_button = computed(() => {
    if (!selected_appointment.value) return false;
    return current_time.value >= selected_appointment.value.start_time - 15 * 60 * 1000;
});

const patient_email = computed(() => {
    if (!selected_appointment.value) return "-";

    if (selected_appointment.value.hosting_type === AppointmentHostingType.MINDGRAM) {
        return selected_appointment.value.mindgram?.patient_email || "-";
    }
    const participant = selected_appointment.value.participants[0];
    if (participant && "email" in participant) {
        return participant.email;
    }
    return "-";
});

const patient_phone = computed(() => {
    if (!selected_appointment.value) return "-";
    const participant = selected_appointment.value.participants[0];
    if (participant && "phone_cc" in participant && "phone" in participant) {
        return `+${participant.phone_cc} ${participant.phone}`;
    }
    return "-";
});

function onDisplayAppointment(appointment: Appointment) {
    selected_appointment.value = appointment;
    if (selected_appointment_owner.value) {
        thid_view_participant.value = selected_appointment_owner.value._id;
    }
    appointment_details_dialog.value = true;

    current_time_update_interval.value = setInterval(() => {
        current_time.value = Date.now();
    }, 1000);
}

function onUpdateAppointment(appointment: Appointment) {
    selected_appointment.value = appointment;
}

function onCloseDetailsView() {
    appointment_details_dialog.value = false;
    appointment_options_menu.value = false;

    clearCurrentTimeInterval();
}

function showTherapistDetails(th_id: string) {
    router.push({
        name: "therapists-profile",
        params: { id: th_id },
        query: {
            p_id: selected_appointment.value ? selected_appointment.value.product._id : ""
        }
    });
    emitter.emit("AppointmentDetailsView::close");
}

/*########################
### CREATE APPOINTMENT ###
####################### */
const appointment_create_dialog = ref(false);

/*########################
### CANCEL APPOINTMENT ###
####################### */
const MindgramAppointmentCancellationUserRecommendationLabel = Object.freeze({
    [MindgramAppointmentCancellationUserRecommendation.APPOINTMENT_WITH_ME]:
        "Proszę umówić wizytę ze mną w innym terminie",
    [MindgramAppointmentCancellationUserRecommendation.APPOINTMENT_ANOTHER_SPECIALIST]:
        "Proszę umówić wizytę z innym specjalistą",
    [MindgramAppointmentCancellationUserRecommendation.CALL_EMERGENCY]:
        "Proszę pilnie skontaktować się z numerem ratunkowym",
    [MindgramAppointmentCancellationUserRecommendation.DO_NOTHING]: "Żadne z powyższych"
});
const MindgramAppointmentCancellationReasonLabel = Object.freeze({
    [MindgramAppointmentCancellationReason.PERSONAL]: "Choroba lub pilna prywatna sprawa",
    [MindgramAppointmentCancellationReason.URGENT]: "Pilne, niespodziewane zdarzenie",
    [MindgramAppointmentCancellationReason.CLIENT_MISMATCH]: "Niedopasowanie do klienta",
    [MindgramAppointmentCancellationReason.CLIENT_UNRELIABLE]:
        "Klient niegodny zaufania - anulował wielokrotnie wizytę",
    [MindgramAppointmentCancellationReason.CLIENT_CRITICAL]: "Klient jest w stanie krytycznym",
    [MindgramAppointmentCancellationReason.SPECIALIST_OTHER]: "Inne"
});

const appointment_cannot_cancel_dialog = ref(false);
const appointment_cancel_dialog = ref(false);
const appointment_cancel_confirm_dialog = ref(false);
const appointment_cancel_loading = ref(false);
const appointment_cancel_reason = ref("");
const appointment_cancel_mindgram_reason = ref<MindgramAppointmentCancellationReason>(
    MindgramAppointmentCancellationReason.PERSONAL
);
const appointment_cancel_mindgram_reason_other = ref<string>("");
const appointment_cancel_mindgram_reason_other_ref = ref<RisifyInputBaseExpose>();
const appointment_cancel_mindgram_recommendation =
    ref<MindgramAppointmentCancellationUserRecommendation>(
        MindgramAppointmentCancellationUserRecommendation.APPOINTMENT_WITH_ME
    );
const appointment_cancel_mindgram_recommendation_note = ref<string>("");
const appointment_cancel_mindgram_recommendation_note_ref = ref<RisifyInputBaseExpose>();
const cancel_reason_txtarea_ref = ref<RisifyInputBaseExpose>();

function onAppointmentCancelRequest() {
    appointment_options_menu.value = false;

    if (
        auth_store.user &&
        auth_store.user.role === UserRole.THERAPIST &&
        !is_more_or_equal_than_24_hours.value
    ) {
        appointment_cannot_cancel_dialog.value = true;
    } else {
        appointment_cancel_dialog.value = true;
    }
}

async function cancelAppointment() {
    if (selected_appointment.value === undefined) return;

    const A: boolean[] = [];

    if (selected_appointment.value.hosting_type === AppointmentHostingType.RISIFY_SELF_HOST) {
        A.push(
            cancel_reason_txtarea_ref.value ? cancel_reason_txtarea_ref.value.validate() : false
        );
    } else if (selected_appointment.value.hosting_type === AppointmentHostingType.MINDGRAM) {
        if (
            appointment_cancel_mindgram_reason.value ===
            MindgramAppointmentCancellationReason.SPECIALIST_OTHER
        ) {
            A.push(
                appointment_cancel_mindgram_reason_other_ref.value
                    ? appointment_cancel_mindgram_reason_other_ref.value.validate()
                    : false
            );
        }
        A.push(
            appointment_cancel_mindgram_recommendation_note_ref.value
                ? appointment_cancel_mindgram_recommendation_note_ref.value.validate()
                : false
        );
    }

    if (A.indexOf(false) !== -1) {
        return;
    }

    appointment_cancel_loading.value = true;

    try {
        const RB: Record<string, string> = {};

        if (selected_appointment.value.hosting_type === AppointmentHostingType.RISIFY_SELF_HOST) {
            RB.reason = appointment_cancel_reason.value;
        } else if (selected_appointment.value.hosting_type === AppointmentHostingType.MINDGRAM) {
            RB.mindgram_cancellation_reason = appointment_cancel_mindgram_reason.value;
            if (
                RB.mindgram_cancellation_reason ===
                MindgramAppointmentCancellationReason.SPECIALIST_OTHER
            ) {
                RB.mindgram_cancellation_reason_other =
                    appointment_cancel_mindgram_reason_other.value;
            }
            RB.mindgram_cancellation_user_recommendation =
                appointment_cancel_mindgram_recommendation.value;
            RB.mindgram_cancellation_user_recommendation_note =
                appointment_cancel_mindgram_recommendation_note.value;
        }

        const res = await httpJsonRequest<ApiAppointmentResponse>(
            `/appointments/${selected_appointment.value._id}/cancel`,
            {
                method: "POST",
                body: JSON.stringify(RB)
            },
            {
                supress_errors: true
            }
        );

        emitter.emit("AppointmentDetailsView::updated", res.appointment);
        appointment_cancel_reason.value = "";
        appointment_cancel_dialog.value = false;

        displayAlert.success({
            title: "Wizyta została anulowana",
            message: "Wizyta została anulowana pomyślnie."
        });
    } catch (e) {
        console.log(e);
    }

    appointment_cancel_loading.value = false;
    appointment_cancel_confirm_dialog.value = false;
    appointment_cancel_dialog.value = false;
    emitter.emit("AppointmentDetailsView::close");
}

/*############################
### RESCHEDULE APPOINTMENT ###
########################### */
const appointment_cannot_reschedule_dialog = ref(false);
const appointment_treschedule_dialog = ref(false);
const date_selector_ref = ref<AppointmentSimpleSlotOrDatetimePickerExpose>();
const appointment_reschedule_loading = ref(false);
const appointment_reschedule_reason = ref("");
const reschedule_reason_txtarea_ref = ref<RisifyInputBaseExpose>();

const is_more_or_equal_than_24_hours = computed(() => {
    if (selected_appointment.value !== undefined) {
        const diff = moment(selected_appointment.value.start_time).diff(
            moment.tz(Date.now(), auth_store.getUserTimezone()),
            "hours"
        );

        return diff >= 24;
    }
});

function toRescheduleAppointment() {
    appointment_options_menu.value = false;

    if (auth_store.user && auth_store.user.role === UserRole.USER) {
        if (!is_more_or_equal_than_24_hours.value) {
            appointment_cannot_reschedule_dialog.value = true;
        } else {
            router.push({
                name: "wiz-fn1-appointment-reschedule",
                query: {
                    appointment: selected_appointment.value ? selected_appointment.value._id : ""
                }
            });
            emitter.emit("AppointmentDetailsView::close");
        }
    } else {
        appointment_treschedule_dialog.value = true;
    }
}

async function confirmRescheduleAppointment() {
    if (
        appointment_reschedule_loading.value ||
        selected_appointment.value === undefined ||
        date_selector_ref.value === undefined
    )
        return;

    const A: boolean[] = [
        reschedule_reason_txtarea_ref.value
            ? reschedule_reason_txtarea_ref.value.validate()
            : false,
        date_selector_ref.value ? date_selector_ref.value.validate() : false
    ];

    if (A.indexOf(false) !== -1) {
        return;
    }

    appointment_reschedule_loading.value = true;

    const AB: Record<string, any> = {};
    if (
        selected_appointment.value.status === AppointmentStatus.PAID ||
        selected_appointment.value.status === AppointmentStatus.UNPAID
    ) {
        AB.reason = appointment_reschedule_reason.value;

        const value = date_selector_ref.value.getValue();

        if (
            selected_appointment.value.hosting_type === AppointmentHostingType.MINDGRAM &&
            moment(value.date_time_from).valueOf() <
                Date.now() + 24 * 60 * 60 * 1000 + 15 * 60 * 1000
        ) {
            appointment_reschedule_loading.value = false;
            displayAlert.error({
                title: "Niepoprawny termin",
                message:
                    "W przypadku wizyt umawianych przez Mindgram nowy termin wizyty musi zostać wyznaczony na co najmniej 24h 15m od aktualnej daty."
            });
            return;
        }

        if (value.type === "slot") {
            AB.slot_id = value.slot_id;
        } else if (value.type === "time-range") {
            AB.start_date_time = value.date_time_from;
            AB.end_date_time = value.date_time_to;
        }
    }
    try {
        const res = await httpJsonRequest<ApiAppointmentRescheduleResponse>(
            `/appointments/${selected_appointment.value._id}/reschedule`,
            {
                method: "POST",
                body: JSON.stringify(AB)
            }
        );

        emitter.emit("AppointmentDetailsView::updated", res.appointment);
        appointment_reschedule_reason.value = "";

        if (res.reschedule_flow === AppointmentRescheduleFlow.ARP) {
            displayAlert.success({
                title: "Utworzono prośbę o przełożenie wizyty",
                message:
                    "Propozycja nowego terminu została wysłana do pacjenta umawiającego wizytę. W przypadku braku reakcji z jego strony inicjatywę przejmie administracja Risify."
            });
        } else {
            displayAlert.success({
                title: "Wizyta została przełożona",
                message: "Wizyta została przełożona na wskazany termin."
            });
        }

        appointment_treschedule_dialog.value = false;

        // router.push({
        //     name: "appointments",
        //     query: {
        //         id: res.appointment._id
        //     }
        // });
    } catch (e: any) {
        console.error(e);
        if (e.error_code === 605) {
            displayAlert.error({
                title: "Wybierz nowy termin wizyty",
                message: "Nie wybrałeś nowego terminu wizyty."
            });
        }
    }

    appointment_reschedule_loading.value = false;
}

/*#############################
### PARTICIPANTS MANAGEMENT ###
############################ */
const participants_dialog = ref(false);
const participants_update_dialog = ref(false);
const selected_participants = ref([] as RisifySelectValue[]);
const selected_participants_local = ref(
    [] as Pick<UserUser, "_id" | "first_name" | "last_name" | "profile_image" | "user_thid">[]
);
const participants_update_loading = ref(false);
const participants_select_ref = ref<RisifyInputBaseExpose>();
const participant_email = ref("");
const participant_email_ref = ref<RisifyInputBaseExpose>();
const send_invitation_loading = ref(false);
const part_dialog_tab = ref<number>(0);

const participants_items = computed(() => {
    if (auth_store.user && auth_store.user.role === UserRole.USER)
        return auth_store.user.linked_users.map(user => {
            return {
                value: user._id,
                text: `${user.first_name} ${user.last_name}`
            } as RisifySelectOption<{}>;
        });
});

function addParticipants() {
    if (
        participants_update_loading.value ||
        selected_appointment.value === undefined ||
        auth_store === undefined ||
        auth_store.user === undefined ||
        auth_store.user.role !== UserRole.USER
    )
        return;

    const A: boolean[] = [
        participants_select_ref.value ? participants_select_ref.value.validate() : false
    ];

    if (A.indexOf(false) !== -1) {
        return;
    }

    selected_participants_local.value = auth_store.user.linked_users.filter(
        it => selected_participants.value.indexOf(it._id) !== -1
    );

    participants_dialog.value = false;
    participants_update_dialog.value = true;
}

async function updateParticipants() {
    if (participants_update_loading.value || selected_appointment.value === undefined) return;

    participants_update_loading.value = true;

    selected_participants.value = selected_participants_local.value.map(it => it._id);

    try {
        if (!Array.isArray(selected_participants.value))
            selected_participants.value = [selected_participants.value];
        const res = await httpJsonRequest<ApiAppointmentResponse>(
            `/appointments/${selected_appointment.value._id}/participants`,
            {
                method: "PUT",
                body: JSON.stringify({
                    participants: [selected_appointment.value.owner, ...selected_participants.value]
                })
            }
        );

        emitter.emit("AppointmentDetailsView::updated", res.appointment);
        fetched_elements_store.updateOrInsertAppointment(res.appointment);
        displayAlert.success({
            title: "Aktualiacja uczestników",
            message: "Uczestnicy zostali zaktualizowani pomyślnie."
        });
    } catch (e) {
        throw e;
    }

    participants_update_dialog.value = false;
    participants_update_loading.value = false;
}

async function sendInvitation() {
    if (send_invitation_loading.value) return;

    const A: boolean[] = [
        participant_email_ref.value ? participant_email_ref.value.validate() : false
    ];

    if (A.indexOf(false) !== -1) return;

    send_invitation_loading.value = true;

    try {
        await httpJsonRequest(`/user-invites`, {
            method: "POST",
            body: JSON.stringify({
                email: participant_email.value,
                actions: [
                    {
                        action: UserInviteAction.LINK_USERS
                    },
                    {
                        action: UserInviteAction.JOIN_APPOINTMENT,
                        appointment: selected_appointment.value?._id
                    }
                ]
            })
        });

        displayAlert.success({
            title: "Zaproszenie wysłane",
            message:
                "Jeżeli podano poprawny adres e-mail, to użytkownik ten otrzyma wiadomość e-mail z zaproszeniem do tej wizyty"
        });

        participants_update_dialog.value = false;
        participant_email.value = "";
    } catch (err) {
        console.error(err);
    }

    send_invitation_loading.value = false;
}

/*#########################
### APPOINTMENT PAYMENT ###
#########################*/

function redirectToPayment() {
    if (selected_appointment.value === undefined) return;
    router.push({
        name: "wiz-fn1-pay-appointment",
        params: { id: selected_appointment.value._id }
    });
    emitter.emit("AppointmentDetailsView::close");
}

/*#######################
### APPOINTMENT START ###
#######################*/
function beginAppointment() {
    if (!selected_appointment.value) return;

    appointment_details_dialog.value = false;

    router.push({
        name: "vc",
        params: { id: selected_appointment.value._id }
    });
}

/*#####################
### APPOINTMENT END ###
#####################*/
const show_complete_appointment_button = computed(() => {
    if (!selected_appointment.value) return false;
    return current_time.value - selected_appointment.value.start_time >= 15 * 60 * 1000;
});
const end_appointment_dialog = ref<boolean>(false);
const end_appointment_dialog_loading = ref<boolean>(false);

async function endAppointment() {
    if (!selected_appointment.value || end_appointment_dialog_loading.value) return;
    end_appointment_dialog_loading.value = true;
    const SW = new Stopwatch();

    try {
        const R = await httpJsonRequest<ApiAppointmentResponse>(
            `/appointments/${selected_appointment.value._id}/complete`,
            {
                method: "POST"
            }
        );

        await SW.waitUntil(750);

        emitter.emit("AppointmentDetailsView::updated", R.appointment);
        fetched_elements_store.updateOrInsertAppointment(R.appointment);
        end_appointment_dialog.value = false;
        displayAlert.success({
            title: "Wizyta zakończona",
            message: "Pomyślnie zakończono i rozliczono wybraną wizytę"
        });
    } catch (err) {
        console.error(err);
    }

    end_appointment_dialog_loading.value = false;
}

/*##########
### CHAT ###
######### */
const appointment_chat_loading = ref(false);

async function toChat() {
    if (selected_appointment.value === undefined) return;

    appointment_chat_loading.value = true;

    const CB: any = {
        type: ChatType.THUS_APPOINTMENT_PRODUCT,
        host: selected_appointment.value.host._id,
        product: selected_appointment.value.product._id
    };

    if (auth_store.user && auth_store.user._id !== selected_appointment.value.owner) {
        CB.source_appointment = selected_appointment.value._id;
    }

    try {
        const res = await httpJsonRequest<ApiChatResponse<ChatThusappr>>("/chats", {
            method: "POST",
            body: JSON.stringify(CB)
        });

        if (res.chat) {
            chats_store.insertChat(res.chat);

            router.push({
                name: "chat",
                params: { id: res.chat._id }
            });
            emitter.emit("AppointmentDetailsView::close");
        }
    } catch (e) {
        console.log(e);
    }

    appointment_chat_loading.value = false;
}

/*####################
### AFFPROG INVITE ###
####################*/
const affprog_invite_link = computed(() => {
    if (!auth_store || !auth_store.user) return "";

    if (Capacitor.isNativePlatform()) {
        return `${APP_DOMAIN}/afp/${Base64.encodeURL(auth_store.user._id)}`;
    } else {
        return `https://${window.location.host}/afp/${Base64.encodeURL(auth_store.user._id)}`;
    }
});

const affprog_invite_dialog = ref<boolean>(false);

function copyInvitationLink() {
    if (affprog_invite_link.value) {
        navigator.clipboard.writeText(affprog_invite_link.value);
        displayAlert.success("Skopiowano do schowka");
    }
}

/*##################
### THS FEEDBACK ###
##################*/
const survey = ref<Survey>();
const survey_loading = ref(false);
const survey_ref = ref<SurveyQuestionsExpose>();

const ths_feedback_dialog = ref(false);
const ths_feedback_dialog_ref = ref<DialogExpose>();
const ths_feedback_loading = ref(false);

const ths_feedback_possible = computed(() => {
    if (selected_appointment.value === undefined) return false;
    return (
        (selected_appointment.value.status === AppointmentStatus.COMPLETED ||
            selected_appointment.value.status === AppointmentStatus.IN_PROGRESS) &&
        auth_store.user &&
        !Object.keys(selected_appointment.value.rated_by).includes(auth_store.user._id)
    );
});

async function onDisplayThsFeedback(call_ended: boolean) {
    if (selected_appointment.value === undefined) return;

    if (ths_feedback_possible.value) {
        // Pobieranie ankiety
        await getThsSurvey({ supress_errors: call_ended });
        ths_feedback_dialog.value = true;
    }
}

async function getThsSurvey(options: { supress_errors?: boolean } = {}) {
    if (survey_loading.value) return;
    survey_loading.value = true;

    const SW = new Stopwatch();

    try {
        const r = await httpJsonRequest<ApiSurveyResponse>(
            `/surveys/primary/by-type/ths_feedback`,
            {},
            {
                supress_errors: options.supress_errors === true
            }
        );
        await SW.waitUntil(500);
        survey.value = r.survey;
    } catch (e) {
        console.error(e);
    }
    survey_loading.value = false;
}
async function sendThsFeedback() {
    if (ths_feedback_loading.value || !survey_ref.value) return;

    const A: boolean[] = [survey_ref.value.validate().validation_status];

    if (A.indexOf(false) !== -1) {
        if (ths_feedback_dialog_ref.value !== undefined) {
            ths_feedback_dialog_ref.value.getMainElement()?.scrollTo({
                top: (survey_ref.value.validate().first_error_anchor?.offsetTop ?? 0) - 70,
                behavior: "smooth"
            });
        }

        return;
    }

    ths_feedback_loading.value = true;
    const SW = new Stopwatch();
    await SW.waitUntil(750);

    const survey_data = survey_ref.value.getValue();

    try {
        const r = await httpJsonRequest<ApiSuccessResponse>(`/survey-responses`, {
            method: "POST",
            body: JSON.stringify({
                appointment: selected_appointment.value?._id,
                survey: survey.value?._id,
                answers: [...survey_data]
            })
        });

        if (r.success) {
            if (auth_store.user && auth_store.user.role === UserRole.USER) {
                const affprog_nps_questions: SurveyQuestion[] = [];

                if (survey.value) {
                    survey.value.questions.forEach(q => {
                        if (
                            q.type === SurveyQuestionType.NPS &&
                            q.nps.affprog_invite_threshold !== -1
                        ) {
                            affprog_nps_questions.push(q);
                        }
                    });
                }

                if (affprog_nps_questions.length > 0 && survey_ref.value !== undefined) {
                    const affprog_satisfied_nps_answer = affprog_nps_questions.find(q => {
                        for (let i = 0; i < survey_data.length; i++) {
                            if (
                                q._id === survey_data[i].question &&
                                q.type === SurveyQuestionType.NPS &&
                                survey_data[i].values.length === 1 &&
                                survey_data[i].values[0] &&
                                q.nps.affprog_invite_threshold <= Number(survey_data[i].values[0])
                            ) {
                                return q;
                            }
                        }
                    });

                    if (affprog_satisfied_nps_answer !== undefined) {
                        ths_feedback_dialog.value = false;
                        affprog_invite_dialog.value = true;
                    }
                }
            }

            displayAlert.success({
                title: "Ocena wysłana",
                message: "Twoja ocena została wysłana pomyślnie."
            });

            if (auth_store.user && selected_appointment.value && survey.value) {
                emitter.emit("AppointmentDetailsView::updated", {
                    ...selected_appointment.value,
                    rated_by: {
                        ...selected_appointment.value.rated_by,
                        [auth_store.user._id]: survey.value._id
                    }
                });
            }
        }
    } catch (e) {
        console.error(e);
    }

    ths_feedback_loading.value = false;
    ths_feedback_dialog.value = false;
}

/*######################
### LIFECYCLES HOOKS ###
##################### */
onMounted(() => {
    emitter.on("AppointmentDetailsView::display-appointment", onDisplayAppointment);
    emitter.on("AppointmentDetailsView::ask-for-ths-feedback", onDisplayThsFeedback);
    emitter.on("AppointmentDetailsView::updated", onUpdateAppointment);
    emitter.on("AppointmentDetailsView::close", onCloseDetailsView);
});

onUnmounted(() => {
    emitter.off("AppointmentDetailsView::display-appointment", onDisplayAppointment);
    emitter.off("AppointmentDetailsView::ask-for-ths-feedback", onDisplayThsFeedback);
    emitter.off("AppointmentDetailsView::updated", onUpdateAppointment);
    emitter.off("AppointmentDetailsView::close", onCloseDetailsView);

    clearCurrentTimeInterval();
});
</script>
