export interface MenuItem {
    id?: string;
    label: string;
    route?: string;
    alt_route?: string;
    handler?: Function;
}

export type BaseColor =
    | "orange"
    | "green"
    | "dark-green"
    | "light-green"
    | "yellow"
    | "red"
    | "white"
    | "gray";

export enum Currency {
    PLN = "pln",
    EUR = "eur"
}

export enum TherapySessionLanguage {
    PL = "pl",
    EN = "en",
    UA = "ua",
    RU = "ru",
    FR = "fr",
    IT = "it"
}
export const TherapySessionLanguageLabel: Record<string, string> = Object.freeze({
    [TherapySessionLanguage.EN]: "angielski",
    [TherapySessionLanguage.PL]: "polski",
    [TherapySessionLanguage.UA]: "ukraiński",
    [TherapySessionLanguage.RU]: "rosyjski",
    [TherapySessionLanguage.FR]: "francuski",
    [TherapySessionLanguage.IT]: "włoski"
});
export const TherapySessionLanguageCountryISOCode: Record<string, string> = Object.freeze({
    [TherapySessionLanguage.EN]: "gb",
    [TherapySessionLanguage.PL]: "pl",
    [TherapySessionLanguage.UA]: "ua",
    [TherapySessionLanguage.RU]: "ru",
    [TherapySessionLanguage.FR]: "fr",
    [TherapySessionLanguage.IT]: "it"
});
export const TherapySessionLanguageSelectItems = Object.entries(TherapySessionLanguageLabel).map(
    it => ({
        text: it[1],
        value: it[0],
        code: TherapySessionLanguageCountryISOCode[it[0]]
    })
);

export enum GA_EVENT {
    SIGN_UP = "sign_up",
    LOGIN = "login",
    ADD_PAYMENT_INFO = "add_payment_info",
    ADD_TO_CART = "add_to_cart",
    SELECT_ITEM = "select_item",
    BEGIN_CHECKOUT = "begin_checkout",
    PURCHASE = "purchase",
    REMOVE_FROM_CART = "remove_from_cart",
    VIEW_ITEM = "view_item",
    START_QUIZ = "start_quiz",
    COMPLETE_QUIZ = "complete_quiz",
    QUIZ_STAGE_UPDATED = "quiz_stage_updated"
}

export enum GA_EVENT_LIST {
    WIZ_FN1_SND = "wiz-fn1-snd",
    APPOINTMENT_BUNDLES = "appointment-bundles"
}

export type GenericCallback<ErrorType = Error, ReturnType = void> = (err?: ErrorType) => ReturnType;
