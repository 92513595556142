<template>
    <RisifyInput
        :id="id"
        :hint="hint"
        :label="label"
        :rules="rules"
        :value="modelValue"
        :show-asterisk="showAsterisk"
        :class-name="className"
        :dense="dense"
        :disabled="disabled"
        v-model:error="error_state"
        @click:append-outer="e => emit('click:append-outer', e)"
        @click:prepend-outer="e => emit('click:prepend-outer', e)"
        ref="custom_input_ref"
    >
        <template
            v-if="hasSlot('label')"
            v-slot:label
        >
            <slot name="label"></slot>
        </template>
        <template
            v-if="hasSlot('hint')"
            v-slot:hint
        >
            <slot name="hint"></slot>
        </template>

        <template
            v-if="hasSlot('prepend-outer')"
            v-slot:prepend-outer
        >
            <slot name="prepend-outer"></slot>
        </template>
        <div
            class="risify-select text-link-1"
            :class="{
                'risify-select--disabled': disabled,
                'risify-select--focused': focused,
                'risify-select--invalid': error_state,
                'risify-select--outlined': outlined,
                'risify-select--dense': dense,
                'risify-select--custom-selection': hasSlot('selection')
            }"
            v-click-outside="
                (e: any) => {
                    if (select_element_ref.menu_visible === true) {
      
                        if(
                            !main_ref ||
                            main_ref.contains(e.target) ||
                            (
                                e.target && 
                                (
                                    e.target.classList.contains('risify-select-element-dropdown') || 
                                    e.target.classList.contains('risify-select-element-dropdown__item')
                                )
                            )
                        ){
                            return;
                        }

                        select_element_ref?.blur();
                        if (computed_menu_type === 'dropdown') {
                            select_element_ref?.closeMenu(false);
                        }
                    }
                }
            "
            @click.self="
                () => {
                    select_element_ref?.toggleMenu();
                    if (select_element_ref?.menu_visible) {
                        focused = true;
                    }
                }
            "
            @keydown.tab="focused = false"
            ref="main_ref"
        >
            <RisifySelectElement
                :items="items"
                :model-value="modelValue"
                @update:model-value="(v: RisifySelectValue | RisifySelectValue[]) => {
                    emit('update:modelValue', v);
                    nextTick(validate);
                }"
                @menu-state-change="
                    v => {
                        if (v === true) {
                            $nextTick(() => {
                                emitter.emit('RisifySelect::opened', el_sid);
                            });
                            $emit('menu:open');
                        } else {
                            $emit('menu:close');
                        }
                    }
                "
                :multiple="multiple"
                :placeholder="placeholder"
                :disabled="disabled"
                :empty-list-msg="emptyListMsg"
                @focus="focused = true"
                @blur="focused = false"
                :error="error_state"
                :dropdown-width="input_width"
                disable-click-outside
                :popper-skidding="0"
                :popper-distance="dense ? 20 : 24"
                :dropdown-alignment="'bottom'"
                :dropdow-fallback-placement="['top']"
                ref="select_element_ref"
                :menu-type="computed_menu_type"
                :dialog-title="dialogTitle"
                :outlined="outlined"
            >
                <template
                    v-slot:prepend-item
                    v-if="hasSlot('prepend-item')"
                >
                    <slot name="prepend-item"></slot>
                </template>
                <template
                    v-slot:item="slotProps"
                    v-if="hasSlot('item')"
                >
                    <slot
                        name="item"
                        :slotProps="slotProps"
                    ></slot>
                </template>
                <template
                    v-slot:append-item
                    v-if="hasSlot('append-item')"
                >
                    <slot name="append-item"></slot>
                </template>
                <template
                    v-slot:selection="slotProps"
                    v-if="hasSlot('selection')"
                >
                    <slot
                        name="selection"
                        :slotProps="slotProps"
                    ></slot>
                </template>
            </RisifySelectElement>
        </div>
        <template
            v-if="hasSlot('append-outer')"
            v-slot:append-outer
        >
            <slot name="append-outer"></slot>
        </template>
    </RisifyInput>
</template>

<script setup lang="ts" generic="T">
import { ref, useSlots, onUnmounted, onMounted, onUpdated, watch, computed, nextTick } from "vue";
import { nanoid } from "nanoid";
import { useWindowSize } from "@vueuse/core";

import { emitter } from "@/plugins/eventEmitter";

import RisifyInput, { RisifyInputProps } from "@/components/form-inputs/RisifyInput.vue";
import RisifySelectElement, {
    RisifySelectElementProps,
    RisifySelectValue
} from "@/components/form-inputs/RisifySelectElement.vue";

type RisifySelectProps<T> = Omit<
    RisifyInputProps<RisifySelectValue | RisifySelectValue[]>,
    "value" | "counter"
> &
    Omit<
        RisifySelectElementProps<T>,
        | "error"
        | "popperDistance"
        | "popperSkidding"
        | "dropdownWidth"
        | "dropdownAlignment"
        | "disableClickOutside"
        | "menuType"
    > & {
        dense?: boolean;
    };

/*###########
### SETUP ###
###########*/
const props = withDefaults(defineProps<RisifySelectProps<T>>(), {
    disabled: false,
    showAsterisk: false,
    dialogTitle: ""
});

const emit = defineEmits<{
    (e: "update:modelValue", v: RisifySelectValue | RisifySelectValue[]): void;
    (e: "click:append-outer", ev: MouseEvent | PointerEvent): void;
    (e: "click:prepend-outer", ev: MouseEvent | PointerEvent): void;
    (e: "menu:open"): void;
    (e: "menu:close"): void;
}>();

defineOptions({
    inheritAttrs: false
});

defineExpose({
    validate,
    resetValidation
});

/*###########
### HOOKS ###
###########*/
const slots = useSlots();

/*###############
### VARIABLES ###
###############*/
const select_element_ref = ref<any>();
const custom_input_ref = ref<any>();
const main_ref = ref<HTMLElement>();

const error_state = ref<boolean>(false);
const focused = ref<boolean>(false);

const input_width = ref<number>(300);
const screen_size = useWindowSize();

const el_sid = nanoid();

/*##############
### COMPUTED ###
##############*/
const computed_menu_type = computed(() => {
    if (screen_size.width.value <= 650 || screen_size.height.value <= 500) return "dialog";
    return "dropdown";
});

/*##############
### WATCHERS ###
##############*/
watch(() => props.error, handleErrorPropChange, { immediate: true });

/*#############
### METHODS ###
#############*/
function validate() {
    if (custom_input_ref.value) {
        return custom_input_ref.value.validate();
    }
    return false;
}
function resetValidation() {
    custom_input_ref?.value.resetValidation();
}

function hasSlot(name: string) {
    return !!slots[name];
}

function doCalcs() {
    if (main_ref.value) {
        input_width.value = main_ref.value.offsetWidth;
    }
}

function handleErrorPropChange(nv: boolean | undefined) {
    if (nv === false || nv === true) {
        error_state.value = nv;
    }
}

function onRisifySelectOpened(sid: string) {
    if (sid !== el_sid && computed_menu_type.value === "dropdown") {
        if (select_element_ref.value && select_element_ref.value.menu_visible) {
            select_element_ref.value.closeMenu(false);
        }
        select_element_ref.value.blur();
    }
}

/*#####################
### LIFECYCLE HOOKS ###
#####################*/
onMounted(() => {
    doCalcs();
    window.addEventListener("resize", doCalcs, { passive: true });
    emitter.on("RisifySelect::opened", onRisifySelectOpened);

    setTimeout(doCalcs, 100);
    setTimeout(doCalcs, 300);
    setTimeout(doCalcs, 500);
    setTimeout(doCalcs, 700);
});

onUnmounted(() => {
    emitter.off("RisifySelect::opened", onRisifySelectOpened);
    window.removeEventListener("resize", doCalcs);
});

onUpdated(() => {
    doCalcs();
});
</script>
