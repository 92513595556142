import { LocationQueryValue } from "vue-router";
import { i18n } from "../plugins/i18n";

export const REGEXES = {
    EMAIL: /[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?/,
    OTP: /^\d{6}$/,
    OBJECT_ID: /^[0-9a-fA-F]{24}$/,
    SHA512: /^[a-f0-9]{128}$/i,
    YEAR: /^[0-9]{4}$/,
    POSITIVE_INT: /^[1-9]\d*$/
};

export function areSamePasswords(value: string, comparedValue: string): boolean | string {
    if (value !== comparedValue) {
        return "Hasła nie są takie same";
    }
    return true;
}

export function isSameAs(value: string, comparedValue: string): boolean | string {
    if (value !== comparedValue) {
        return "Wartości nie są takie same";
    }
    return true;
}

export function isDifferentThan(value: string, comparedValue: string): boolean | string {
    if (value === comparedValue) {
        return "Adres nie może być taki sam jak aktualny";
    }
    return true;
}

export function isEmail(value: string): boolean | string {
    if (value.length > 127) {
        return "Adres e-mail może mieć co najwyżej 127 znaków długości";
    }
    if (!REGEXES.EMAIL.test(value)) {
        return "Niepoprawny adres e-mail";
    }
    return true;
}

export function isValidOtp(value: string): boolean | string {
    const PV = value.replace(/ /g, "");
    if (!REGEXES.OTP.test(PV)) {
        return "Kod musi składać się z 6 cyfr";
    }
    return true;
}

export function isValidBlikCode(value: string): boolean | string {
    const PV = value.replace(/ /g, "");
    if (!/^[0-9]{6}$/.test(PV)) {
        return "Podaj poprawny kod BLIK";
    }
    return true;
}

// export function isRequired(value: string): boolean | string {
//     if (value === "") {
//         return "To pole jest wymagane";
//     }
//     return true;
// }
// TODO - zmieniona funkcja isRequired
export function isRequired<T>(value: T | T[]): boolean | string {
    if (typeof value === "string" && value.trim() === "") {
        return i18n.global.t("global.validation_errors.required");
    } else if (Array.isArray(value) && value.length === 0) {
        return i18n.global.t("global.validation_errors.required");
    }
    return true;
}

export function isSafePassword(value: string): boolean | string {
    const hasUpperCase = /[A-Z]/.test(value);
    const hasLowerCase = /[a-z]/.test(value);
    const hasNumber = /[0-9]/.test(value);

    if (!hasUpperCase) {
        return "Hasło musi zawierać conajmniej jedną wielką literę";
    }
    if (!hasLowerCase) {
        return "Hasło musi zawierać conajmniej jedną małą literę";
    }
    if (!hasNumber) {
        return "Hasło musi zawierać conajmniej jedną cyfrę";
    }

    return true;
}

export function minMax(min: number, max: number) {
    return (v: string) => {
        if (v.length < min) {
            return i18n.global.t("global.validation_errors.min_length", { n: min });
        }
        if (v.length > max) {
            return i18n.global.t("global.validation_errors.max_length", { n: max });
        }
        return true;
    };
}

export function numberMinMax(min: number, max: number) {
    return (v: number | string) => {
        if (typeof v == "string") {
            v = parseInt(v);
        }
        if (v < min) {
            return `Minimalna wartość to ${min}`;
        }
        if (v > max) {
            return `Maksymalna wartość to ${max}`;
        }
        return true;
    };
}

export function isDiscountCodeValid(v: string) {
    if (!/^[a-z0-9][a-z0-9_\-]{2,30}$/i.test(v)) {
        return "Proszę podać poprawny kod zniżkowy";
    }

    return true;
}

export function isValidCssColor(str: string) {
    const s = new Option().style;
    s.color = str;
    return s.color !== "";
}

/**
 * Metoda sprawdza, czy przekazana data jest dniem dzisiejszym.
 * @param {number|Date} date Data do sprawdzenia
 * @returns {boolean}
 */
export function isTodayDate(date: number | Date = Date.now()) {
    return new Date(date).toISOString().split("T")[0] == new Date().toISOString().split("T")[0];
}

export function isRouteQueryParamValidObjectId(id: LocationQueryValue | LocationQueryValue[]) {
    if (id === undefined) return false;
    return typeof id === "string" && REGEXES.OBJECT_ID.test(id);
}
